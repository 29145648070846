export const NETWORK_NAME = 'Avalanche'
export const NAT_SYMBOL = 'AVAX'
export const WNAT_ADDRESS = '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'.toLowerCase()
export const EXPLORER_LINK_BASE = 'https://snowtrace.io'
export const EXPLORER_NAME = 'SnowTrace'
export const SUBGRAPH_HOST = 'https://graph-avalanche.pangolin.network'
export const SUBGRAPH_NAME_PANGOLIN = 'avalanche/pangolin-v2'
export const SUBGRAPH_NAME_BLOCKS = 'avalanche/blocks'
export const SWAP_FEE_TO_LP = 0.003
export const SYMBOL_MAX_DISPLAY_LENGTH = 9
export const NAME_MAX_DISPLAY_LENGTH = 16
export const SOCIAL_LINK_TWITTER = 'https://twitter.com/pangolindex'
export const PANGOLIN_TOKEN_IMAGE_REPO_BASE = 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114'
export const SOCIAL_LINK_DISCORD = 'https://discord.com/invite/jC2gJneDAp'
export const SUPPORTED_TOKEN_LIST_URLS = [
  'https://raw.githubusercontent.com/pangolindex/tokenlists/main/pangolin.tokenlist.json',
]
export const TOKEN_BLACKLIST = [
  '0xa47a05ed74f80fa31621612887d26df40bcf0ca9'.toLowerCase(),
  '0x97b99b4009041e948337ebca7e6ae52f9f6e633c'.toLowerCase(),
  '0x2bc8834bc217f15df898fcab896e2b3e3673faf6'.toLowerCase(),
  '0xf20d962a6c8f70c731bd838a3a388d7d48fa6e15'.toLowerCase(),
  '0xde3a24028580884448a5397872046a019649b084'.toLowerCase(),
  '0x408d4cd0adb7cebd1f1a1c33a0ba2098e1295bab'.toLowerCase(),
  '0xb3fe5374f67d7a22886a0ee082b2e2f9d2651651'.toLowerCase(),
  '0xba7deebbfc5fa1100fb055a87773e1e99cd3507a'.toLowerCase(),
  '0xf39f9671906d8630812f9d9863bbef5d523c84ab'.toLowerCase(),
  '0x39cf1bd5f15fb22ec3d9ff86b0727afc203427cc'.toLowerCase(),
  '0x8ce2dee54bb9921a2ae0a63dbb2df8ed88b91dd9'.toLowerCase(),
  '0x99519acb025a0e0d44c3875a4bbf03af65933627'.toLowerCase(),
]
export const PAIR_BLACKLIST = [
]

/*
 * Mapping between address and CoinGecko coin id
 * Using CoinGecko API: https://api.coingecko.com/api/v3/coins/list
 */
export const COIN_ID_MAP = {
  ['0x60781c2586d68229fde47564546784ab3faca982'.toLowerCase()]: 'pangolin',
  ['0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'.toLowerCase()]: 'avalanche',
  ['0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664'.toLowerCase()]: 'usd-coin',
  ['0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab'.toLowerCase()]: 'ethereum',
  ['0xc7198437980c041c805a1edcba50c1ce5db95118'.toLowerCase()]: 'tether',
  ['0x8729438eb15e2c8b576fcc6aecda6a148776c0f5'.toLowerCase()]: 'benqi',
  ['0xd1c3f94de7e5b45fa4edbba472491a9f4b166fc4'.toLowerCase()]: 'avalaunch',
  ['0x5947bb275c521040051d82396192181b413227a3'.toLowerCase()]: 'chainlink',
  ['0x50b7545627a5162f82a992c33b87adc75187b218'.toLowerCase()]: 'bitcoin',
  ['0x59414b3089ce2af0010e7523dea7e2b35d776ec7'.toLowerCase()]: 'yield-yak',
  ['0x61ecd63e42c27415696e10864d70ecea4aa11289'.toLowerCase()]: 'rugpull-prevention',
}

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  ALL_TIME: 'All time',
}
export const PAIR_CHART_VIEW_OPTIONS = {
  VOLUME: 'Volume',
  LIQUIDITY: 'Liquidity',
  RATE0: 'Rate 0',
  RATE1: 'Rate 1',
}
